export const hide = elem => {
  elem.classList.add("display-none");
  elem.setAttribute("aria-hidden", "true");

  return this;
};

export const show = elem => {
  elem.classList.remove("display-none");
  elem.setAttribute("aria-hidden", "false");

  return this;
};

export const getWindowWidth = () => {
  return window.innerWidth && document.documentElement.clientWidth
    ? Math.min(window.innerWidth, document.documentElement.clientWidth)
    : window.innerWidth || document.documentElement.clientWidth;
};
