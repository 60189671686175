import "magnific-popup";

const handlePropertyPhotoPopup = () => {
  $(".section__content--photos").magnificPopup({
    delegate: ".lightbox",
    gallery: {
      enabled: true,
    },
    type: "image",
  });
};

const handlePropertyTourModal = () => {
  $(".js-popup-modal").magnificPopup({
    focus: ".js-popup-modal-dismiss",
    midClick: true,
    modal: true,
    preloader: false,
    type: "inline",
  });

  $(document).on("click", ".js-popup-modal-dismiss", function (evt) {
    evt.preventDefault();

    $.magnificPopup.close();
  });
};

const handleFilterButtons = () => {
  var filterButtons = document.getElementById("js-filter-buttons");
  if (filterButtons === null) return;

  var filterProperties = filterButtons.querySelector(".js-filter-properties");
  var filters = document.getElementById("js-filter-options");
  if (filterProperties === null || filters === null) return;

  filterProperties.addEventListener("click", function (evt) {
    if (filters.classList.contains("filter-options--mobile-hide")) {
      filters.classList.remove("filter-options--mobile-hide");
      filterProperties.setAttribute("aria-expanded", "true");
    } else {
      filters.classList.add("filter-options--mobile-hide");
      filterProperties.setAttribute("aria-expanded", "false");
    }
  });
};

const main = () => {
  handlePropertyPhotoPopup();
  handlePropertyTourModal();

  handleFilterButtons();
};

$(function () {
  if (
    $(
      ".apartments-index, .commercials-index, .houses-index, .buildings-show, .properties-show"
    ).length
  ) {
    main();
  }
});
