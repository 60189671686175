/* @TODO: Fix focus handling and resizing events */
import anime from "animejs/lib/anime.es.js";

import { getWindowWidth, hide, show } from "./utils.js";

const BODY = document.body;
const HEADER = document.getElementById("js-header");
const HEADER_SEARCH_CONTAINER = HEADER.querySelector(".js-search-bar");
const HEADER_SEARCH_CLOSE = HEADER.querySelector(".js-search-bar-close");
const HEADER_SEARCH_INPUT = HEADER.querySelector(".js-search-bar-input");
const HEADER_SEARCH_OPEN = HEADER.querySelector(".js-search-bar-open");
const MENU = HEADER.querySelector(".js-nav");
const MENU_BACK_BUTTONS = HEADER.querySelectorAll(".js-nav-back");
const MENU_TOGGLE = HEADER.querySelector(".js-hamburger");
const OVERLAY = document.getElementById("js-site-overlay");
const TOP_NAV_CATEGORY_CONTAINER = HEADER.querySelector(".nav__list--top");
const TOP_NAV_CATEGORY_LINKS = TOP_NAV_CATEGORY_CONTAINER.querySelectorAll(
  ".js-nav-cat"
);
const TOP_NAV_CATEGORY_DROPDOWNS = TOP_NAV_CATEGORY_CONTAINER.querySelectorAll(
  ".nav__item--dropdown"
);

let currExpandedCategory = null;

const checkForViewportChange = () => {
  let throttled = false;

  const handleViewportChange = () => {
    if (!throttled) {
      throttled = true;

      if (getWindowWidth() >= 1088) {
        handleMoveIntoDesktopViewport();
      } else {
        handleMoveIntoMobileViewport();
      }

      setTimeout(() => {
        throttled = false;
      }, 75);
    }
  };

  // Fire on initial load
  handleViewportChange();

  window.addEventListener("resize", handleViewportChange);
};

const handleMoveIntoDesktopViewport = () => {
  MENU.setAttribute("aria-hidden", "false");
  MENU.classList.remove("visibility-hidden");
  MENU.classList.add("is-active");
  MENU_TOGGLE.setAttribute("aria-expanded", "true");
  MENU_TOGGLE.classList.add("is-active");
  OVERLAY.classList.remove("is-active");
  BODY.classList.remove("body-no-scroll");
};

const handleMoveIntoMobileViewport = () => {
  MENU.setAttribute("aria-hidden", "true");
  MENU.classList.add("visibility-hidden");
  MENU.classList.remove("is-active");
  MENU_TOGGLE.setAttribute("aria-expanded", "false");
  MENU_TOGGLE.classList.remove("is-active");
  OVERLAY.classList.remove("is-active");
  BODY.classList.remove("body-no-scroll");
};

const menuToggle = () => {
  const handleMenuToggle = () => {
    const isMenuExpanded = MENU_TOGGLE.getAttribute("aria-expanded") === "true";
    const isMenuHidden = MENU.getAttribute("aria-hidden") === "true";

    closeExpandedCategory();

    MENU_TOGGLE.classList.toggle("is-active");
    MENU_TOGGLE.setAttribute("aria-expanded", !isMenuExpanded);
    MENU.setAttribute("aria-hidden", !isMenuHidden);
    MENU.classList.toggle("visibility-hidden");
    MENU.classList.toggle("is-active");
    OVERLAY.classList.toggle("is-active");
    BODY.classList.toggle("body-no-scroll");
  };

  MENU_TOGGLE.addEventListener("click", handleMenuToggle);
};

const closeExpandedCategory = () => {
  if (currExpandedCategory) {
    const currCategoryLink = currExpandedCategory.querySelector(".js-nav-cat");
    const currCategorySecondaryNav = currExpandedCategory.querySelector(
      ".nav__list--secondary"
    );

    // Close expanded category
    currCategoryLink.setAttribute("aria-expanded", "false");
    currCategorySecondaryNav.setAttribute("aria-hidden", "true");
    currCategorySecondaryNav.classList.add("display-none");

    // Reset X position of expanded subnav
    anime({
      targets: currCategorySecondaryNav,
      translateX: 0,
      duration: 0
    });

    currExpandedCategory = null;
  }
};

const menuCategoryClick = () => {
  const handleMenuCategoryClick = evt => {
    evt.preventDefault();

    const catLink = evt.currentTarget;
    const catSecondaryNav = catLink.parentNode.querySelector(
      ".nav__list--secondary"
    );

    // @TODO: @FIX: Ignore clicks when user's mouse is hovering over catLink elem
    if (getWindowWidth() >= 1088 && catLink.matches(":hover")) {
      return;
    }

    const isCatLinkExpanded = catLink.getAttribute("aria-expanded") === "true";
    const isCatSecondaryNavHidden =
      catSecondaryNav.getAttribute("aria-hidden") === "true";

    // Close any currently opened category
    closeExpandedCategory();

    // Toggle expanded and hidden attributes
    catLink.setAttribute("aria-expanded", !isCatLinkExpanded);
    catSecondaryNav.setAttribute("aria-hidden", !isCatSecondaryNavHidden);
    catSecondaryNav.classList.toggle("display-none");

    anime({
      targets: catSecondaryNav,
      translateX: -480,
      duration: 50
    });

    // Store a reference to the clicked nav
    currExpandedCategory = catLink.parentNode;
  };

  TOP_NAV_CATEGORY_LINKS.forEach(link => {
    link.addEventListener("click", handleMenuCategoryClick);
  });
};

const menuCategoryHover = () => {
  const onMenuCategoryMouseEnter = evt => {
    // Trigger mouseover actions only in 1088px+ viewports
    if (getWindowWidth() < 1088) {
      return;
    }

    // Close any currently opened categories
    closeExpandedCategory();

    const elem = evt.currentTarget;
    const catLink = elem.querySelector(".js-nav-cat");
    const catSecondaryNav = elem.querySelector(".nav__list--secondary");

    catLink.setAttribute("aria-expanded", "true");
    catSecondaryNav.setAttribute("aria-hidden", "false");
    catSecondaryNav.classList.remove("display-none");

    // Store a reference to the hovered elem
    currExpandedCategory = elem;
  };

  const onMenuCategoryMouseLeave = evt => {
    // Trigger mouseover actions only in 1088px+ viewports
    if (getWindowWidth() < 1088) {
      return;
    }

    const elem = evt.currentTarget;
    const catLink = elem.querySelector(".js-nav-cat");
    const catSecondaryNav = elem.querySelector(".nav__list--secondary");

    catLink.setAttribute("aria-expanded", "false");
    catSecondaryNav.setAttribute("aria-hidden", "true");
    catSecondaryNav.classList.add("display-none");
  };

  TOP_NAV_CATEGORY_DROPDOWNS.forEach(link => {
    link.addEventListener("mouseenter", onMenuCategoryMouseEnter);
    link.addEventListener("mouseleave", onMenuCategoryMouseLeave);
  });
};

const menuBack = () => {
  const handleMenuBackClick = evt => {
    const backButton = evt.currentTarget;
    const currCatSecondaryNavId = backButton.closest(".nav__list--secondary")
      .id;
    const currCatSecondaryNav = document.getElementById(currCatSecondaryNavId);
    const currCatLink = document.querySelector(
      `[aria-controls="${currCatSecondaryNavId}"]`
    );

    currCatLink.setAttribute("aria-expanded", "false");

    anime({
      complete: () => {
        currCatSecondaryNav.setAttribute("aria-hidden", "true");
      },
      duration: 150,
      targets: currCatSecondaryNav,
      translateX: 0
    });
  };

  MENU_BACK_BUTTONS.forEach(backButton => {
    backButton.addEventListener("click", handleMenuBackClick);
  });
};

const searchBar = () => {
  const handleSearchBarActivate = () => {
    HEADER_SEARCH_CONTAINER.classList.add("is-active");
    setTimeout(() => {
      HEADER_SEARCH_INPUT.focus();
    }, 0);
  };

  const handleSearchBarDeactivate = () => {
    HEADER_SEARCH_CONTAINER.classList.remove("is-active");
    setTimeout(() => {
      HEADER_SEARCH_INPUT.focus();
    }, 100);
  };

  HEADER_SEARCH_CLOSE.addEventListener("click", _evt => {
    HEADER_SEARCH_CONTAINER.classList.contains("is-active")
      ? handleSearchBarDeactivate()
      : handleSearchBarActivate();
  });

  HEADER_SEARCH_OPEN.addEventListener("click", _evt => {
    HEADER_SEARCH_CONTAINER.classList.contains("is-active")
      ? handleSearchBarDeactivate()
      : handleSearchBarActivate();
  });
};

const main = () => {
  checkForViewportChange();
  menuToggle();
  menuBack();
  menuCategoryClick();
  menuCategoryHover();
  searchBar();
};

main();
