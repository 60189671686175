import "select2/dist/js/select2.full";

const main = () => {
  $(".find-home-form .amenities-select").select2({
    dropdownCssClass: "amenities-dropdown",
    multiple: true,
    placeholder: "Amenities",
    tags: true
  });
};

$(document).ready(function() {
  if ($(".welcome-index").length) {
    main();
  }
});
